<template>
  <div class="-search">
    <div class="c_main -pad-btm">
			<div class="inner">
				<nav class="c_pnkz">
					<ul class="c_pnkz_list">
						<li><a href="/">ホーム</a></li>
						<li>検索</li>
					</ul>
				</nav>
				<section class="serach">
					<h1 class="serach_ttl">「{{ searchKeyword }}」の検索結果</h1>
          <contents-list :contents="contents"></contents-list>
          <div :class="{c_load: loading}"></div>
					<div v-if="!loading && !contents.length" style="text-align: center; padding:20px;">
            検索結果に一致するコンテンツはありません。
          </div>
					<paginate
            v-if="contents.length"
						v-model="page"
            :page-count="getPageCount"
            :page-range="3"
            :margin-pages="2"
            :click-handler="clickCallback"
            :prev-text="'&lt; 前へ'"
            :next-text="'次へ &gt;'"
            :container-class="'c_pager'"
						:prev-link-class="'-prev'"
						:next-link-class="'-next'"
						no-li-surround
            >
          </paginate>
				</section>
			</div>
		</div>
  </div>
</template>

<script>
import ContentsList from "@/components/ContentsList";
export default {
  components: {
    ContentsList,
  },
	data() {
    return {
			contents: [],
			total: 0,
			loading: false,
			page: 1,
    }
  },
  watch: {
    '$route.query': function () {
      this.fetch()
    },
  },
	computed: {
		getPageCount: function() {
      return Math.ceil(this.total / 5);
    },
    searchKeyword() {
      if (this.$route.query.s) {
        return this.$route.query.s 
      } else if (this.$route.query.tag) {
        return `#${this.$route.query.tag}`
      } else {
        return '検索キーワードなし'
      }
    }
	},
  created() {
    this.fetch()
  },
  methods: {
		fetch() {
      this.contents = []
      this.loading = true

      let params = {
        search: this.$route.query.s,
        tag: this.$route.query.tag,
        page: this.page
      }
      this.$authAxios.get('/api/contents/search', {
				params: params
      })
      .then((response) => {
				this.contents = response.data.result
				this.total = response.data.count
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        this.loading = false
      })
		},
		clickCallback(pageNum) {
      window.scrollTo({top:0, behavior: "instant"})
			this.page = Number(pageNum)
			this.fetch()
    },
  }
}
</script>

<style scoped>
@import "~@/css/search.css";
</style>